<template>
    <div :class="closing ? 'menu menu-close' : 'menu'" @click="tapClose" :style="{ height: height + 'px' }">
        <div :class="closing ? 'content content-close' : 'content content-open'" @click.stop="">
            <a class="close" @click="tapClose">
                <SvgIcon name="close" size="24" />
            </a>
            <div style="height:0.84rem"></div>
            <a class="menu_item" @click="changeTheme">
                <SvgIcon name="theme" size="36" />
                <div class="menu_title">主题风格</div>
            </a>
            <a class="menu_item" @click="editInfo">
                <SvgIcon name="base_info" size="36" />
                <div class="menu_title">基本信息</div>
            </a>
            <a class="menu_item" @click="account">
                <SvgIcon name="safe" size="36" />
                <div class="menu_title">账号安全</div>
            </a>
            <a class="menu_item" @click="history">
                <SvgIcon name="history" size="36" />
                <div class="menu_title">访问历史</div>
            </a>
            <div class="expand"></div>
            <a class="logout" @click="logout">
                退出登录
            </a>
            <!-- <div v-if="showBottom" class="safebottom"></div> -->
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'Menu',
    props: {
        msg: String,
        close: Function
    },
    data() {
        return {
            closing: false,
            showBottom: false,
            height: document.documentElement.clientHeight
        }
    },
    mounted() {
        // if (localStorage.getItem('clientHeight')==document.documentElement.clientHeight){
        //     alert('zz'+localStorage.getItem('clientHeight'))
        // }
        // else {
        //     alert('sss'+localStorage.getItem('clientHeight')+'  '+document.documentElement.clientHeight)
        // }
        var h = localStorage.getItem('clientHeight')
        // alert(h+' '+document.documentElement.clientHeight)

        if (window.history.length > 1 && this.isWeiXin() && this.isiOS) {
            this.showBottom = true
        }
        if (h && h != document.documentElement.clientHeight) {
            this.showBottom = false
        }
    },
    methods: {
        isWeiXin() {
            //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
            var ua = window.navigator.userAgent.toLowerCase();
            //通过正则表达式匹配ua中是否含有MicroMessenger字符串
            if (ua.match(/MicroMessenger/i) == 'micromessenger') {
                return true;
            } else {
                return false;
            }
        },
        isiOS() {
            var u = navigator.userAgent;
            return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        },
        tapClose() {
            this.closing = true
            var _this = this;
            setTimeout(() => {
                _this.close();
            }, 300)

        },
        changeTheme() {
            setTimeout(() => {
                this.tapClose()
            }, 300)
            global.theme_alive = true
            localStorage.setItem('socials', JSON.stringify(global.userInfo))
            global.canChangeRoute = true
            this.$router.push({
                path: '/settings/theme',
                query: {
                    // theme:JSON.stringify(global.theme),
                    selId: global.userInfo.theme_style_id,
                    // userInfo: JSON.stringify(global.userInfo)
                }
            }).catch(err=>{})
        },
        editInfo() {
            setTimeout(() => {
                this.tapClose()
            }, 300)
            global.canChangeRoute = true
            this.$router.push({
                path: '/settings/profile'
            }).catch(err=>{})
        },
        account() {
            setTimeout(() => {
                this.tapClose()
            }, 300)
            global.canChangeRoute = true
            this.$router.push({
                path: '/settings/account'
            }).catch(err=>{})
        },
        history() {
            setTimeout(() => {
                this.tapClose()
            }, 300)
            global.canChangeRoute = true
            this.$router.push({
                path: '/settings/history'
            }).catch(err=>{})
        },
        logout() {
            var _this = this
            this.$modal(require('../assets/images/toast_tip.png'), '确定要退出当前账号吗？', '立即退出', '取消', () => {
                Promise.all([
                    this.$post(this.$api.frontData, {
                        code: 'guide_welcome',
                        value: '0'
                    }),
                    this.$post(this.$api.frontData, {
                        code: 'guide_more',
                        value: '0'
                    }),
                    this.$post(this.$api.frontData, {
                        code: 'guide_drag',
                        value: '0'
                    }),
                    this.$post(this.$api.frontData, {
                        code: 'guide_first_link',
                        value: '0'
                    })
                ]).then(data => {
                    global.index_alive = false;
                    global.modal.show = false;
                    global.token = ''
                    global.hasLogin = false
                    global.userInfo = null
                    global.loginStep = 0
                    localStorage.clear()
                    global.canChangeRoute = true
                    _this.tapClose()
                    this.$router.replace({ path: '/welcome' });
                })

                // this.$router.push({ path: '/wel', query: { account: status ? this.phone : this.email, is_phone: status } });
            }, () => {
                global.modal.show = false;
            })
        }
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.menu {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    // bottom: 0;
    background: #000000BF;
    // opacity: 0;
    z-index: 10;
    animation: menuAni 0.3s 0s linear forwards;
    -webkit-animation: menuAni 0.3s 0s linear forwards;
    // box-sizing: content-box;
    // padding-bottom: constant(safe-area-inset-bottom);
    // padding-bottom: env(safe-area-inset-bottom);

    .content {
        width: 1.2rem;
        height: 100%;
        background: #000000E6;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-left: -1.2rem;
        // transform: ;

        .close {
            position: absolute;
            left: 0.24rem;
            top: 0.24rem;
        }

        .menu_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0.36rem;

            .menu_title {
                color: #fff;
                margin-top: 0.08rem;
                font-size: 0.14rem;
                line-height: 0.2rem;
            }
        }

        .logout {
            color: #fff;
            font-size: 0.14rem;
            margin-bottom: 0.5rem;
        }
    }

    .content-open {
        animation: contentAni 0.2s 0s linear forwards;
    }

    .content-close {
        transform: translateX(1.2rem);
        animation: contentCloseAni 0.2s 0s linear forwards;
    }

    @keyframes contentAni {
        0% {
            // margin-left: -1.2rem;
            transform: translateX(0);
        }

        100% {
            transform: translateX(1.2rem);
            // margin-left: 0rem;
        }
    }

    @keyframes contentCloseAni {
        0% {
            // margin-left: 0rem;
            transform: translateX(1.2rem);
        }

        100% {
            // margin-left: -1.2rem;
            transform: translateX(0);
        }
    }
}

.menu-close {
    opacity: 1;
    animation: menuCloseAni 0.3s 0s linear forwards;
}

@keyframes menuCloseAni {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes menuAni {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.safebottom {
    height: 50px;
}
</style>