var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"ellipsis-container",style:({
    marginLeft: _vm.theme.margin_left * 0.01 + 'rem',
    marginRight: _vm.theme.margin_right * 0.01 + 'rem',
    width:3.75-_vm.theme.margin_left * 0.01-_vm.theme.margin_right * 0.01+'rem',
    fontSize: _vm.theme.font_size * 0.01 + 'rem',
    color: _vm.theme.color,
    lineHeight: _vm.theme.line_height * 0.01 + 'rem',
})},[_vm._v(" "+_vm._s(_vm.expanded?_vm.content:_vm.preContent)+" "),(!_vm.expanded && _vm.canExpand)?_c('span',{style:({color:_vm.theme.icon.color}),on:{"click":_vm.clickBtn}},[_vm._v("...展开")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }