<template>
    <div class="indexcontent" id="indexcontent" ref="indexcontent" :style="{ opacity: opacity }">
        <div class="share-bg" id="share-bg1" v-if="!choose">
            <img src="../assets/images/share_bg.png" alt="" class="share-cover">
            <img crossorigin="anonymous" :src="userInfo.avatar" alt="" class="share-avatar">
            <div class="share-content1">
                <img src="../assets/images/welcome_social_bg.png" alt="" class="share-content-img">
                <span class="share-name">我是<span style="color:#FFF700FF">{{ userInfo.nickname }}</span></span>
                <span class="share-location">来自中国·北京，快来看看我的主页</span>
                <div class="share-rows">
                    <div v-for="(item, index) in userInfo.socials" :key="index">
                        <img crossorigin="anonymous" :src="item.logo" alt="" class="share-item-img"
                            v-if="index < 5 && item.logo">
                        <img crossorigin="anonymous" src="../assets/images/custom_link.png" alt=""
                            class="share-item-img" v-if="index < 5 && !item.logo">
                    </div>

                </div>
            </div>
            <img v-if="shareObj.qrUrl" :src="shareObj.qrUrl" alt="" class="share-qrcode">
        </div>
        <div class="bg1" v-if="theme && theme.background.type == 'linear-color'" :style="{
            height: choose ? '100vh' : '100%',
            background: 'linear-gradient(' + theme.background.linear_colors + ')',
        }"></div>
        <div class="bg1" v-if="theme && theme.background.type == 'single-color'"
            :style="{ height: choose ? '100vh' : '100%', background: theme.background.color }"></div>


        <div class="content" id="content" v-if="theme"
            :style="{ height: choose ? '100vh' : '100%', background: theme.background.type == 'skew' ? theme.background.bottom_color : 'transparent' }">
            <div class="bg skew_bg"
                v-if="theme && theme.background.type == 'skew' && theme.background.top_left_y != theme.background.top_right_y"
                :style="{ background: theme.background.bottom_color }">
                <div :style="{
                    overflow:'hidden',
                    background: theme.background.seprate_line_color, width: '100%',
                    WebkitClipPath: `polygon(0 0,0 ${theme.background.top_left_y * 0.01 + theme.background.seprate_line_width * 0.01 + 'rem'},${width + 'px'} ${theme.background.top_right_y * 0.01 + theme.background.seprate_line_width * 0.01 + 'rem'}, ${width + 'px'} 0)`,
                    height: theme.background.top_left_y > theme.background.top_right_y ? theme.background.top_left_y * 0.01 + theme.background.seprate_line_width * 0.01 + 'rem' : theme.background.top_right_y * 0.01 + theme.background.seprate_line_width * 0.01 + 'rem',
                    clipPath: `polygon(0 0,0 ${theme.background.top_left_y * 0.01 + theme.background.seprate_line_width * 0.01 + 'rem'},${width + 'px'} ${theme.background.top_right_y * 0.01 + theme.background.seprate_line_width * 0.01 + 'rem'}, ${width + 'px'} 0)`
                }">
                    <div :style="{
                        overflow:'hidden',
                        background: theme.background.top_color, width: '100%',
                        height: theme.background.top_left_y > theme.background.top_right_y ? theme.background.top_left_y * 0.01 + 'rem' : theme.background.top_right_y * 0.01 + 'rem',
                        // clipPath:'polygon(0 0,0 300px,300px 250px, 200px 0)'
                        WebkitClipPath:`polygon(0 0,0 ${theme.background.top_left_y * 0.01 + 'rem'},${width + 'px'} ${theme.background.top_right_y * 0.01 + 'rem'}, ${width + 'px'} 0)`,
                        clipPath: `polygon(0 0,0 ${theme.background.top_left_y * 0.01 + 'rem'},${width + 'px'} ${theme.background.top_right_y * 0.01 + 'rem'}, ${width + 'px'} 0)`
                    }">

                    </div>
                </div>

            </div>
            <div class="bg skew_bg"
                v-if="theme && theme.background.type == 'skew' && theme.background.top_left_y == theme.background.top_right_y"
                :style="{ background: theme.background.bottom_color }">
                <div
                    :style="{ background: theme.background.top_color, width: '100%', height: '400px', display: 'flex' }">
                    <div class="skew1" :style="{
                        background: theme.background.bottom_color,
                        width: '100%',
                        marginTop: theme.background.top_right_y * 0.01 + 'rem',
                        borderTopLeftRadius: theme.background.top_left_corner * 0.01 + 'rem',
                        borderTopRightRadius: theme.background.top_right_corner * 0.01 + 'rem',
                    }"></div>
                </div>
            </div>
            <img :src="userInfo.avatar" alt="" class="avatar" :style="{
                width: theme.avatar.width * 0.01 + 'rem',
                height: theme.avatar.height * 0.01 + 'rem',
                borderWidth: theme.avatar.border_width * 0.01 + 'rem',
                borderColor: theme.avatar.border_color.length > 0 ? theme.avatar.border_color : 'transparent',
                borderStyle: 'solid',
                backgroundColor: theme.avatar.border_color.length > 0 ? theme.avatar.border_color : 'transparent',
                marginTop: theme.avatar.top * 0.01 + 'rem',
                boxShadow: theme.avatar.shadows ? theme.avatar.shadows : '',
                flexShrink: 0
            }" />
            <div class="nickname" :style="{
                fontWeight: theme.nickname.font_weight,
                fontSize: theme.nickname.font_size * 0.01 + 'rem',
                color: theme.nickname.color,
                marginTop: theme.nickname.top * 0.01 + 'rem',
            }">
                {{ userInfo.nickname }}
            </div>
            <div class="location" :style="{
                marginTop: theme.location.top * 0.01 + 'rem',
            }">
                <div class="location_content" :style="{
                    width: theme.location.height * 0.01 + 'rem',
                    height: theme.location.height * 0.01 + 'rem',
                    borderRadius: '50%',
                    borderWidth: theme.location.border_width + 'px',
                    borderColor: theme.location.border_color,
                    borderStyle: 'solid',
                    boxSizing: 'border-box'
                }">
                    <span :class="userInfo.sex == 'FEMALE' ? 'icon-female' : 'icon-male'"
                        :style="{ color: theme.location.gender.color, fontSize: theme.location.gender.width + 'px' }"></span>
                </div>
                <div class="location_content" :style="{
                    marginLeft: theme.location.space_width * 0.01 + 'rem',
                    height: theme.location.height * 0.01 + 'rem',
                    paddingLeft: theme.location.address.padding_horizontal * 0.01 + 'rem',
                    paddingRight: theme.location.address.padding_horizontal * 0.01 + 'rem',
                    borderRadius: theme.location.height * 0.005 + 'rem',
                    borderWidth: theme.location.border_width + 'px',
                    borderColor: theme.location.border_color,
                    borderStyle: 'solid',
                    boxSizing: 'border-box'
                }">
                    <span class="icon-location"
                        :style="{ color: theme.location.address.color, fontSize: theme.location.gender.width + 'px' }"></span>
                    <div :style="{
                        marginLeft: '0.04rem',
                        fontSize: theme.location.address.font_size * 0.01 + 'rem',
                        color: theme.location.address.color,
                    }">
                        {{ userInfo.country }} {{ userInfo.city }}
                    </div>
                </div>
            </div>
            <div :style="{ height: theme.signature.top * 0.01 + 'rem', flexShrink: 0 }"></div>
            <expand-text ref="intro" :content="userInfo.signature" :theme="theme.signature" :isExpand="isExpandIntro" />
            <div :style="{ height: (theme.social_item.margin_top * 0.01 - 0.05) + 'rem' }" style="flex-shrink:0"></div>
            <div style="z-index:2;" id="first-item" v-if="!isPreview && isMyPage && userInfo.socials.length>1">
                <Container @drop="onDrop" @drag-start="dragStart" dragClass="drag" dropClass="drop" :drag-begin-delay="500">
                    <Draggable v-for="item in userInfo.socials" :key="item.id" style="overflow: visible;">
                        <div style="display: flex;padding:0rem 0.15rem;flex-shrink: 0;" class="social_item"
                            @click="goDetail(item)">
                            <div class="social_item" :style="{
                                width: theme.social_item.width * 0.01 + 'rem',
                                height: theme.social_item.height * 0.01 + 'rem',
                                borderRadius: theme.social_item.border_radius * 0.01 + 'rem',
                                borderWidth: theme.social_item.border_width * 0.01 + 'rem',
                                borderColor: theme.social_item.border_color,
                                borderStyle: 'solid',
                                backgroundColor: theme.social_item.background_color,
                                marginBottom: theme.social_item.space_height * 0.01 + 'rem',
                                // marginTop: theme.social_item.space_height * 0.01 + 'rem',
                                paddingLeft: theme.social_item.padding_horizontal * 0.01 + 'rem',
                                paddingRight: theme.social_item.padding_horizontal * 0.01 + 'rem',
                                background: theme.social_item.background_color,
                                boxShadow: theme.social_item.shadows,
                                // boxSizing:'content-box'
                            
                            }" @click="goDetail(item)">
                                <img v-if="item.logo" :src="item.logo" alt="">
                                <img v-else src="../assets/images/other_link.png"
                                    style="width:0.24rem;height:0.24rem" />
                                <div class="center">
                                    <div class="row">
                                        <span :style="{
                                            color: theme.social_item.text_color,
                                            fontWeight: theme.social_item.font_weight,
                                        
                                        }" class="item-title">{{ item.user_nick && item.user_nick.length > 0 ?
        item.user_nick : item.name
}}</span>
                                        <a style="padding-left:0.04rem;" v-if="!isMyPage && item.user_nick && item.user_nick.length > 0"
                                            @click.stop="copy(item.user_nick && item.user_nick.length > 0 ? item.user_nick : item.name)">
                                            <span class="icon-copy"
                                                :style="{ color: theme.social_item.arrow_color }"></span>
                                        </a>
                                    </div>

                                    <span v-if="item.description && item.description.length > 0" :style="{
                                        color: theme.social_item.desc_color,
                                        fontSize: theme.social_item.desc_size * 0.01 + 'rem'
                                    }" class="item-desc">{{ item.description }}</span>
                                </div>
                                <a style="display:flex;align-items:center;justify-content:center;background-color: transparent;"
                                    @click.stop="edit(item)" v-if="isMyPage">
                                    <span class="icon-edit" style="font-size:0.16rem"
                                        :style="{ color: theme.social_item.arrow_color }"></span>
                                </a>
                                <div style="width:0.14rem;"></div>
                                <span v-if="item.show_type == 'QR'" class="icon-qrcode" style="font-size:0.16rem"
                                    :style="{ color: theme.social_item.qr_icon_color }"></span>
                                <span class="icon-right" style="font-size:0.16rem"
                                    :style="{ color: theme.social_item.arrow_color }"></span>
                            </div>
                        </div>
                    </Draggable>
                </Container>
            </div>
            <div style="z-index:2" id="first-item" v-else>
                <a v-for="(item) in userInfo.socials" :key="item.id" class="social-item" style="z-index: 2;"
                    @click="goDetail(item)">
                    <div class="social_item" :style="{
                        width: theme.social_item.width * 0.01 + 'rem',
                        height: theme.social_item.height * 0.01 + 'rem',
                        borderRadius: theme.social_item.border_radius * 0.01 + 'rem',
                        borderWidth: theme.social_item.border_width * 0.01 + 'rem',
                        borderColor: theme.social_item.border_color,
                        borderStyle: 'solid',
                        backgroundColor: theme.social_item.background_color,
                        marginBottom: theme.social_item.space_height * 0.01 + 'rem',
                        paddingLeft: theme.social_item.padding_horizontal * 0.01 + 'rem',
                        paddingRight: theme.social_item.padding_horizontal * 0.01 + 'rem',
                        background: theme.social_item.background_color,
                        boxShadow: theme.social_item.shadows,
                        flexShrink: 0
                    
                    }">
                        <img v-if="item.logo" :src="item.logo" alt="">
                        <img v-else src="../assets/images/other_link.png" style="width:0.24rem;height:0.24rem" />
                        <div class="center">
                            <div class="row">
                                <span :style="{
                                    color: theme.social_item.text_color,
                                    fontWeight: theme.social_item.font_weight
                                }" class="item-title">{{ item.user_nick && item.user_nick.length > 0 ? item.user_nick :
        item.name
}}</span>
                                <a style="padding-left:0.04rem;" v-if="!isMyPage && item.user_nick && item.user_nick.length > 0"
                                    @click.stop="copy(item.user_nick && item.user_nick.length > 0 ? item.user_nick : item.name)">
                                    <span class="icon-copy" :style="{ color: theme.social_item.arrow_color }"></span>
                                </a>
                            </div>

                            <span v-if="item.description && item.description.length > 0" :style="{
                                color: theme.social_item.desc_color,
                                fontSize: theme.social_item.desc_size * 0.01 + 'rem'
                            }" class="item-desc">{{ item.description }}</span>
                        </div>
                        <a style="display:flex;align-items:center;justify-content:center;background-color: transparent;"
                            @click.stop="edit(item)" v-if="isMyPage">
                            <span class="icon-edit" style="font-size:0.16rem"
                                :style="{ color: theme.social_item.arrow_color }"></span>
                        </a>
                        <div style="width:0.14rem;"></div>
                        <span v-if="item.type == 'QR'" class="icon-qrcode" style="font-size:0.16rem"
                            :style="{ color: theme.social_item.qr_icon_color }"></span>
                        <span class="icon-right" style="font-size:0.16rem"
                            :style="{ color: theme.social_item.arrow_color }"></span>
                    </div>
                </a>
            </div>
            <div v-if="isMyPage" id="add-item" class="social_item add_link" @click="addLink" :style="{
                width: theme.social_item.width * 0.01 + 'rem',
                height: theme.social_item.height * 0.01 + 'rem',
                borderRadius: theme.social_item.border_radius * 0.01 + 'rem',
                borderWidth: theme.social_item.border_width * 0.01 + 'rem',
                borderColor: theme.social_item.border_color,
                borderStyle: 'solid',
                marginBottom: theme.social_item.space_height * 0.01 + 'rem',
                paddingLeft: theme.social_item.padding_horizontal * 0.01 + 'rem',
                paddingRight: theme.social_item.padding_horizontal * 0.01 + 'rem',
                background: theme.social_item.background_color,
                boxShadow: theme.social_item.shadows,
                zIndex: guideStep == 1 ? 3 : 0,
            
            }">
                <span class="icon-link" style="font-size:0.24rem"
                    :style="{ color: theme.social_item.addlink_icon_color }"></span>
                <div class="center" :style="{
                    color: theme.social_item.text_color,
                    fontWeight: theme.social_item.font_weight
                }">添加链接</div>
                <div class="expand"></div>
                <span class="icon-right" style="font-size:0.16rem"
                    :style="{ color: theme.social_item.arrow_color }"></span>
            </div>
            <div style="height:10px;flex-shrink:0"></div>
            <span class="copyright" :style="{ color: theme.copy_right.color }">2022 © LINKBaeBae 链接吧，宝贝！</span>
            <div class="safefooter" v-if="showFooter"></div>
        </div>



        <div class="toolbar" v-if="guideStep != 2">
            <div class="toolbarbg" :style="{ background: theme.toolbar.background_color, opacity: toolbarOpacity }">
            </div>
            <a v-if="isPreview" style="padding-left: 0.2rem;position: relative;" @click="back">
                <span style="font-size:0.14rem" :style="{ color: theme.toolbar.left_icon.color }">退出预览</span>
            </a>
            <a v-else-if="!isMyPage" style="padding-left: 0.2rem;position: relative;" @click="goMyPage">
                <span class="icon-home" style="font-size:0.24rem"
                    :style="{ color: theme.toolbar.left_icon.color }"></span>
            </a>

            <a v-else style="padding-left: 0.2rem;position: relative;" @click="showMenu = true">
                <span class="icon-menu" style="font-size:0.24rem"
                    :style="{ color: theme.toolbar.left_icon.color }"></span>
            </a>
            <div class="expand"></div>
            <a v-if="isMyPage" @click="preview" :style="{ position: 'relative', zIndex: guideStep == 3 ? 3 : 0 }">
                <span class="icon-preview" style="font-size:0.24rem"
                    :style="{ color: theme.toolbar.left_icon.color }"></span>
            </a>
            <div style="width: 0.24rem"></div>
            <a @click="share" style="padding-right: 0.2rem;position: relative;"
                :style="{ zIndex: guideStep == 3 ? 3 : 0 }">
                <span class="icon-share" style="font-size:0.24rem"
                    :style="{ color: theme.toolbar.left_icon.color }"></span>
            </a>
        </div>
        <slot />
        <Menu v-if="showMenu" :close="close" />
        <div :class="qrObj.needScroll ? 'qr-bg qr-bg-scroll' : 'qr-bg qr-bg-center'" v-if="previewQR"
            @click="previewQR = false">
            <div class="close icon-close" style="color:#fff">

            </div>
            <img class="qrcode" :src="qrObj.url" alt="" @click.stop=""
                :style="{ width: qrObj.width + 'px', height: qrObj.height + 'px' }">
        </div>
        <div class="qr-bg share-container" v-if="shareObj.show" @click="shareObj.show = false">
            <div class="close icon-close" style="color:#fff">
            </div>
            <div class="share-img-bg">
                <img :src="shareObj.url" alt="" class="share-cover">
            </div>

            <div class="share-line"></div>

            <div class="share-list">
                <a class="share-item">
                    <img src="../assets/images/share_weibo.png" class="share-icon" alt="" @click.stop="shareLink">
                    <div class="share-name">微博</div>
                </a>
                <div style="width:0.24rem"></div>
                <a class="share-item">
                    <img src="../assets/images/share_wechat.png" class="share-icon" alt="" @click.stop="shareLink">
                    <div class="share-name">微信</div>
                </a>
                <div style="width:0.24rem"></div>
                <a class="share-item">
                    <img src="../assets/images/share_qq.png" class="share-icon" alt="" @click.stop="shareLink">
                    <div class="share-name">QQ</div>
                </a>
                <div style="width:0.24rem"></div>
                <a class="share-item">
                    <img src="../assets/images/share_link.png" class="share-icon" alt="" @click.stop="copyLink">
                    <div class="share-name">复制链接</div>
                </a>
            </div>

        </div>
    </div>
</template>
  
<script>
import html2canvas from 'html2canvas';
import { get, post } from '../config/http';
import api from '../config/api';
import $ from 'jquery';
import canvg from 'canvg';
import { Container, Draggable } from 'vue-smooth-dnd';
import Menu from '../components/menu.vue';
import ExpandText from '../components/expand-text.vue'
import { isiOS, isWeiXin } from '../config/utils';
import inobounce from 'inobounce';
export default {
    props: {
        // msg: String
        userInfo: Object,
        theme: Object,
        id: String,
        socials: Array,
        guideStep: Number,
        isGuide: Boolean,
        isPreview: Boolean,
        choose: {
            type: Boolean,
            value: false
        },
        indexLoad: {
            type: Number,
            value: 0
        }
    },
    components: {
        Container,
        Draggable,
        Menu,
        ExpandText
    },
    data() {
        var isMyPage = false
        if (global.hasLogin) {

            // var uid = this.$route.query.uid;
            var uid = this.$route.params.id//this.$route.query.uid;

            isMyPage = true
            if (uid != undefined && uid != global.userInfo.id) {
                isMyPage = false;
            }
        }
        let htmlW = document.documentElement.clientWidth;
        let W = htmlW > 750 ? 750 : htmlW;
        let scaleScreen = W / 375;

        if (this.isPreview) {
            isMyPage = false
        }
        return {
            //index/478e00adf7151e7761f00b7fd5c20447
            width: scaleScreen * 375,
            scaleScreen: scaleScreen,
            showMenu: false,
            toolbarOpacity: 0,
            isMyPage: isMyPage,
            previewQR: false,
            rows: 3,
            opacity: 0,
            showFooter: false,
            isExpandIntro: false,
            qrObj: {
                url: '',
                width: 1,
                height: 1,
                needScroll: false
            },
            shareObj: {
                show: false,
                url: '',
                qrUrl: '',
                cover: null,
                width: 1,
                height: 1
            }
            // qrUrl: ''
        };
    },
    watch: {
        theme(data) {
            this.$forceUpdate()
        },
        $route(to, from) {
            if (isiOS() && isWeiXin() && global.historyLength == 1 && global.historyLength != history.length) {
                this.showFooter = true
            }
        }
    },
    activated() {
        this.$nextTick(() => {
            if (this.scrollTop) {
                const scrollview = document.getElementById('content')
                if (scrollview) {
                    scrollview.scrollTop = this.scrollTop
                }
            }
        })

    },
    mounted() {
        const scrollview = document.getElementById('content')
        scrollview.addEventListener('scroll', this.scrollChange, true)
        this.loadQRCode()
        this.opacity = 1;
        if (!global.historyLength) {
            global.historyLength = history.length
        }
        var _this = this
        this.$nextTick(()=>{
            setTimeout(()=>{
                _this.refresh()
            },50)
        })

        if (isiOS()){
            inobounce.enable()
        }
    },

    beforeDestroy() {
        inobounce.disable()
        // const scrollview = document.getElementById('indexcontent')
        // scrollview.removeEventListener('scroll', this.scrollview, true)
    },

    methods: {
        //
        
        refresh() {
            var height = this.$refs.indexcontent.offsetHeight
            if (height== document.documentElement.clientHeight){
                this.showFooter = false
            }
            else {
                this.showFooter = true
            }
        },
        hidenExpand() {
            this.$refs.intro.hidenExpand()
        },
        loadQRCode() {
            var _this = this
            let qrObj = new Image()
            var url = 'https://m.link.dev.liveplus.fun/index/' + this.userInfo.id;
            qrObj.src = this.$api.baseUrl + this.$api.qr + `?data=${url}`
            qrObj.onload = () => {
                _this.shareObj.qrUrl = this.$api.baseUrl + this.$api.qr + `?data=${url}`
            }
        },
        close() {
            this.showMenu = false;

        },
        back() {
            this.$router.back()
        },

        copy(str) {
            navigator.clipboard.writeText(str)
            this.$toast('复制成功', require('../assets/images/toast_success.png'))
        },
        copyLink() {
            var url = 'https://m.link.dev.liveplus.fun/' + this.userInfo.id;
            navigator.clipboard.writeText(url)
            this.$toast('分享链接已复制\n快去分享给你的好', require('../assets/images/toast_success.png'))
            this.shareObj.show = false
        },
        async shareLink() {
            this.shareObj.show = false
            // const blob = await (await fetch(this.shareObj.url)).blob();
            // const file = new File([blob], 'share.png', { type: blob.type });
            var url = 'https://m.link.dev.liveplus.fun/' + this.userInfo.id;
            window.navigator.share({ text: 'LinkBaeBae 链接吧，宝贝！', title: 'LinkBaeBae 链接吧，宝贝！', url: url, files: [global.sharefile] })
        },
        isURL(str) {
            //转换为小写
            var v = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$', 'i');
            return v.test(str);
        },

        goDetail(item) {
            if (item.show_type == 'LINK') {
                var url = item.user_url
                if (url.indexOf('http') == -1) {
                    url = 'https://' + url
                }
                if (this.isURL(url)) {
                    window.open(url, '_blank')
                    // window.open(item.user_url, '_blank')
                }
                else {
                    this.$toast('当前链接不可用', require('../assets/images/toast_tip.png'))
                }

            }
            else {
                this.previewQR = true
                this.qrObj.url = item.user_url.length == 0 ? item.qr_url : item.user_url
                this.$toast('长按图片识别或保存')
                let imgObj = new Image()
                imgObj.src = item.user_url.length == 0 ? item.qr_url : item.user_url
                var _this = this;
                imgObj.onload = () => {
                    var height = 1 * imgObj.height / imgObj.width
                    var scroll = false;
                    if (height * 250 > document.body.clientHeight) {
                        scroll = true
                    }
                    _this.qrObj.width = 250
                    _this.qrObj.height = height * 250
                    _this.qrObj.needScroll = scroll
                    // _this.qrObj.width = imgObj.width
                    // _this.qrObj.height = imgObj.height
                }
            }
        },

        goMyPage() {
            this.$router.push({ path: '/welcome' }).catch(err=>{})
        },
        preview() {
            if (this.userInfo.socials.length==0){
                this.$toast('添加链接后，即可预览', require('../assets/images/toast_tip.png'))
                return
            }
            global.canChangeRoute = true
            this.$router.push({ path: '/preview', query: { theme: JSON.stringify(this.theme), isPreview: true } }).catch(err=>{})
            // this.$router.push({path:'/'})
        },
        share() {
            if (this.userInfo.socials.length==0){
                this.$toast('添加链接后，即可分享', require('../assets/images/toast_tip.png'))
                return
            }
            this.canvasFun()
        },

        scrollChange(e) {
            this.scrollTop = e.target.scrollTop
            this.toolbarOpacity = e.target.scrollTop / 100

        },
        dragStart(){
            var vibrateSupport = 'vibrate' in navigator
            if (vibrateSupport){
                navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;  
                navigator.vibrate(100)
            }
        },
        applyDrag(arr, dragResult) {
            const { removedIndex, addedIndex, payload } = dragResult
            if (removedIndex === null && addedIndex === null) return arr

            const result = [...arr]
            let itemToAdd = payload

            if (removedIndex !== null) {
                itemToAdd = result.splice(removedIndex, 1)[0]
            }

            if (addedIndex !== null) {
                result.splice(addedIndex, 0, itemToAdd)
            }

            return result
        },

        onDrop(dropResult) {
            console.log(dropResult)
            var list = this.userInfo.socials
            const { removedIndex, addedIndex } = dropResult
            if (removedIndex === null && addedIndex === null) return

            let item = list.splice(removedIndex, 1)[0]
            list.splice(addedIndex, 0, item)

            this.userInfo.socials = list
            this.orderLinks()
            // this.items = this.applyDrag(this.items, dropResult);
        },
        orderLinks() {
            var ids = [];

            for (var i = 0; i < this.userInfo.socials.length; i++) {
                ids.push(this.userInfo.socials[i].id)
            }
            this.$put(this.$api.userSocials, { ids: ids })
        },
        addLink() {
            global.canChangeRoute = true
            this.$router.push({ path: '/platform', query: { more: 1 } }).catch(err=>{})
        },
        edit(social) {
            global.canChangeRoute = true
            var obj = null
            this.socials.map((item) => {
                if (item.id == social.social_id) {
                    obj = item;
                }
            })
            obj ??= {
                id: '',
                name: social.name,
                logo: '',
                types: ['LINK', 'QR'],
                description_example: '',
                url_example: ''
            }
            localStorage.setItem('lastSocial', JSON.stringify(obj))
            this.$router.push({
                path: '/platform/link', query: {
                    is_edit: true,
                    social_id: social.id
                }
            }).catch(err=>{})
        },


        generateItems(count, creator) {
            const result = []
            for (let i = 0; i < count; i++) {
                result.push(creator(i))
            }
            return result
        },
        async canvasFun() {
            var _this = this
            html2canvas(document.querySelector('#share-bg1'), {
                useCORS: true, // 【重要】开启跨域配置
                scale: 2,
                allowTaint: true, // 允许跨域图片
            }).then((canvas) => {
                const dataURL = canvas.toDataURL('image/jpeg', 1.0);
                _this.shareObj.url = dataURL
                _this.shareObj.show = true
                _this.shareObj.width = 200
                _this.shareObj.height = 200
            });

        }
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
// .social_item:active{
//     transform: scale(0.95);
// }
* {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
}

.safefooter {
    flex-shrink: 0;
    height: 80px;
}

.share-img-bg {
    width: 200px;
    height: 352px;
    box-shadow: 0 13px 13px 0 rgba(0, 0, 0, 0.50);
}

.share-cover {
    width: 200px;
    height: 352px;
}

.share-bg {
    width: 375px;
    height: 660px;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: -660px;

    .share-cover {
        width: 375px;
        height: 660px;
    }

    .share-avatar {
        position: absolute;
        left: 24px;
        top: 254px;
        width: 84px;
        height: 84px;
        border-radius: 50%;
        object-fit: cover;
    }

    .share-qrcode {
        width: 100px;
        height: 100px;
        position: absolute;
        bottom: 99px;
        left: 138px;
    }

    .share-content1 {
        position: absolute;
        left: 82px;
        top: 232px;
        width: 270px;
        height: 128px;
        display: flex;
        flex-direction: column;
        padding-left: 60px;
        padding-top: 24px;
        box-sizing: border-box;

        .share-content-img {
            position: absolute;
            width: 270px;
            height: 128px;
            left: 0;
            top: 0;
        }

        .share-name {
            position: relative;
            font-size: 20px;
            line-height: 20px;
            font-weight: bold;
            color: #fff;
            text-align: left;
        }

        .share-location {
            position: relative;
            text-align: left;
            margin-top: 8px;
            color: #fff;
            font-size: 12px;
            line-height: 20px;
        }

        .share-rows {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 8px;

            .share-item-img {
                width: 24px;
                height: 24px;
                border-radius: 6px;
                margin-right: 12px;
            }
        }
    }
}

.smooth-dnd-disable-touch-action * {
    touch-action: auto;
    -ms-touch-action: auto;
}

.indexcontent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.canvas_bg {
    transform-origin: left top;
    transform: scale(0.5);
}

.n_canvas {
    width: 3.75rem;
    overflow-x: hidden;
    height: 100vh;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bg {
    width: 100%;
    height: 100%;

    .skew1 {
        display: flex;
        flex: 1;
    }
}

.bg1 {
    display: flex;
    width: 100%;
    height: 100vh;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // background:linear-gradient(360deg, #E8ECEF 10%, #FFFFFF 100%);
    // background-color: pink;
}

.toolbar {

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 0.72rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 10;

    .toolbarbg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}

.content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100vh;
    // bottom: 0;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-overflow-scrolling: touch; // 触摸增强

    .skew_bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 500px;
    }

    .avatar {
        position: relative;
        object-fit: cover;
        border-radius: 50%;
        z-index: 1;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        // background-color: red;
    }

    .nickname {
        z-index: 1;
        position: relative;
    }

    .location {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .location_content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    }

    .intro {
        position: relative;
        z-index: 1;
        overflow: hidden;
        display: flex;
        flex-shrink: 0;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // text-overflow: clip;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .social_item {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        flex-shrink: 0;

        img {
            width: 0.24rem;
            height: 0.24rem;
            border-radius: 0.06rem;
        }

        .center {
            margin-left: 0.1rem;
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .copyright {
        z-index: 1;
        font-size: 0.1rem;
        margin-bottom: 0.2rem;
    }
}

.content::-webkit-scrollbar {
    display: none;
}

.add_link {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.qr-bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000000BF;
    z-index: 100;
    overflow: scroll;

    .close {
        position: fixed;
        right: 0.24rem;
        top: 0.24rem;
        font-size: 0.24rem;
    }

    .qrcode {
        object-fit: cover;
    }
}

.qr-bg-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.qr-bg-scroll {
    padding: 0.7rem 0;
}

.share-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .share-bg {
        position: relative;
        overflow: hidden;
        box-shadow: 0rem 0.24rem 0.24rem 0rem rgba(0, 0, 0, 0.5);

        .share-shadow {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 107px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 36%, #131314 51%, #131314 100%);

            .share-shadow-content {
                position: absolute;
                left: 20px;
                bottom: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .qr {
                width: 53px;
                height: 53px;
                object-fit: cover;
                position: absolute;
                left: 22px;
                bottom: 38px;
            }

            .note {
                margin-top: -10px;
                font-size: 15px;
                transform-origin: left bottom;
                transform: scale(0.333);
                color: #fff;
            }

            .logo {
                width: 83px;
                position: absolute;
                right: 20px;
                bottom: 20px;
            }

        }

        .cover {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }
}

.share-list {
    // margin-top: 0.53rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .share-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .share-icon {
            width: 0.44rem;
            height: 0.44rem;
            object-fit: cover;
        }

        .share-name {
            margin-top: 0.05rem;
            line-height: 0.16rem;
            font-size: 0.12rem;
            color: #fff;
        }
    }
}

.item-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 1.5rem;
    text-align: left;
}

.item-desc {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 1.5rem;
    text-align: left;
}

.drag {
    // opacity: 0.95;
    transform: scale(1.05);
}

.drop {
    // opacity: 1;
    transform: scale(1.0);
}

.share-line {
    width: 2.48rem;
    height: 0.01rem;
    background-color: #FFFFFF33;
    margin-bottom: 0.2rem;
    margin-top: 0.32rem;
}
</style>