<template>
    <span class="ellipsis-container" :style="{
        marginLeft: theme.margin_left * 0.01 + 'rem',
        marginRight: theme.margin_right * 0.01 + 'rem',
        width:3.75-theme.margin_left * 0.01-theme.margin_right * 0.01+'rem',
        fontSize: theme.font_size * 0.01 + 'rem',
        color: theme.color,
        lineHeight: theme.line_height * 0.01 + 'rem',
    }">
        {{ expanded?content:preContent }}
        <span :style="{color:theme.icon.color}" @click="clickBtn" v-if="!expanded && canExpand">...展开</span>
    </span>
</template>
  
<script>
import $ from 'jquery'

export default {
    props: {
        content: {
            type: String,
            default: ''
        },
        theme: {
            type: Object
        },
        isExpand:{
            type:Boolean,
            value:false
        }
    },
    data() {
        return {
            textLength: 0,
            beforeRefresh: null,
            expanded:this.isExpand,
            preContent:'',
            canExpand:false
        }
    },
    computed: {
    },
    watch: {
        isExpand(v){
            this.expanded = this.isExpand
        },
        content(v){
            this.updateContent()
        }
    },
    mounted() {
        this.updateContent()
    },
    beforeDestroy() {
    },
    methods: {
        clickBtn(event) {
            this.expanded = true
        },
        hidenExpand(){
            this.expanded = false
        },
        updateContent(){
            var subString = this.content
        var maxLength = 50
        if (this.content.length>maxLength){
            subString = this.content.substring(0,maxLength)
        }
        var array = subString.split('\n')
        if (array.length>3){
            subString = array[0]+'\n'+array[1]+'\n'+array[2]
        }
        this.preContent = subString
        if (subString != this.content){
            this.canExpand = true
        }
        }
    }
}
</script>

<style lang="scss" scoped>
.ellipsis-container {
    text-align: left;
    position: relative;
    line-height: 1.5;
    padding: 0 !important;
    white-space: pre-line;
    .textarea-container {
        position: absolute;
        left: 0;
        right: 0;
        pointer-events: none;
        opacity: 0;
        z-index: -1;
    }

    textarea {
        vertical-align: middle;
        padding: 0;
        resize: none;
        overflow: hidden;
        font-size: inherit;
        line-height: inherit;
        outline: none;
        border: none;
    }

}

.ellipsis{
    color: #13131433;
}

.ellipsis-btn{
    color: #13131433;
}
</style>



<!-- <template>
    <div class="ellipsis-container" :style="{
        marginLeft: theme.margin_left * 0.01 + 'rem',
        marginRight: theme.margin_right * 0.01 + 'rem',
        fontSize: theme.font_size * 0.01 + 'rem',
        color: theme.color,
        lineHeight: theme.line_height * 0.01 + 'rem',
        
    }">
        <div class="textarea-container" ref="shadow">
            <textarea :rows="rows" readonly tabindex="-1" style="background:transparent"></textarea>
        </div>
        {{ showContent }}
        <slot name="ellipsis" v-if="(textLength < content.length) || btnShow" >
            <span style="color:#13131433">{{ ellipsisText }}</span>
            <span class="ellipsis-btn" @click="clickBtn">{{ btnText }}</span>
        </slot>
    </div>
</template>
  
<script>
import resizeObserver from 'element-resize-detector'
import $ from 'jquery'
const observer = resizeObserver()

export default {
    props: {
        content: {
            type: String,
            default: ''
        },
        theme: {
            type: Object
        },
        btnText: {
            type: String,
            default: '展开'
        },
        ellipsisText: {
            type: String,
            default: '...'
        },
        rows: {
            type: Number,
            default: 6
        },
        btnShow: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            textLength: 0,
            beforeRefresh: null
        }
    },
    computed: {
        showContent() {
            const length = this.beforeRefresh ? this.content.length : this.textLength
            return this.content.substr(0, this.textLength)
        },
        watchData() { // 用一个计算属性来统一观察需要关注的属性变化
            return [this.content, this.btnText, this.ellipsisText, this.rows, this.btnShow]
        }
    },
    watch: {
        watchData: {
            immediate: true,
            handler() {
                this.refresh()
            }
        },
    },
    mounted() {
        observer.listenTo(this.$refs.shadow, () => this.refresh())
    },
    beforeDestroy() {
        observer.uninstall(this.$refs.shadow)
    },
    methods: {
        refresh() { // 计算截取长度，存储于textLength中
            this.beforeRefresh && this.beforeRefresh()
            let stopLoop = false
            this.beforeRefresh = () => stopLoop = true
            this.textLength = this.content.length
            const checkLoop = (start, end) => {
                if (stopLoop || start + 1 >= end) return
                const rect = this.$el.getBoundingClientRect()
                const shadowRect = this.$refs.shadow.getBoundingClientRect()
                const overflow = rect.bottom > shadowRect.bottom
                overflow ? (end = this.textLength) : (start = this.textLength)
                this.textLength = Math.floor((start + end) / 2)
                this.$nextTick(() => checkLoop(start, end))
            }
            this.$nextTick(() => checkLoop(0, this.textLength))
        },
        // 展开按钮点击事件向外部emit
        clickBtn(event) {
            this.$emit('expand', event)
        },
    }
}
</script>

<style lang="scss" scoped>
.ellipsis-container {
    text-align: left;
    position: relative;
    line-height: 1.5;
    padding: 0 !important;
    z-index: 0;
    // white-space: pre-line;
    .textarea-container {
        position: absolute;
        left: 0;
        right: 0;
        pointer-events: none;
        opacity: 0;
        z-index: -1;
    }

    textarea {
        vertical-align: middle;
        padding: 0;
        resize: none;
        overflow: hidden;
        font-size: inherit;
        line-height: inherit;
        outline: none;
        border: none;
    }

}

.ellipsis{
    color: #13131433;
}

.ellipsis-btn{
    color: #13131433;
}
</style> -->